import {
  AnnouncementBlockData,
  CallToActionTarget,
  DropdownItemData,
  NavigationMenuItemData,
} from '@wunderdogsw/finbb-dms-theme'
import { graphql, useStaticQuery } from 'gatsby'

type ApplicationNavbarData = {
  announcementBlock: AnnouncementBlockData
  navigationCallToActionCaption: string
  navigationCallToActionTarget: CallToActionTarget
  navigationMenu: NavigationMenuItemData[]
  siteLogo: {
    resize: {
      src: string
    }
  }
  siteLogoInverse: {
    resize: {
      src: string
    }
  }
  userMenu: DropdownItemData[]
}

export default (): ApplicationNavbarData => {
  const {
    contentfulApplication,
  }: { contentfulApplication: ApplicationNavbarData } = useStaticQuery(graphql`
    query {
      contentfulApplication(title: { eq: "FINGENIOUS" }) {
        announcementBlock {
          ... on Node {
            __typename
            ... on ContentfulAnnouncementBlock {
              ...AnnouncementBlock
            }
          }
        }
        navigationCallToActionCaption
        navigationCallToActionTarget {
          ... on Node {
            __typename
            ... on ContentfulArticlePage {
              id
              fields {
                route
              }
            }
            ... on ContentfulLink {
              id
              url
            }
            ... on ContentfulPage {
              id
              fields {
                route
              }
            }
          }
        }
        navigationMenu {
          ... on Node {
            __typename
            ... on ContentfulArticlePage {
              ...ArticlePage
            }
            ... on ContentfulDropdown {
              ...Dropdown
            }
            ... on ContentfulLink {
              ...Link
            }
            ... on ContentfulPage {
              ...Page
            }
          }
        }
        siteLogo {
          resize(height: 96) {
            src
          }
        }
        siteLogoInverse {
          resize(height: 96) {
            src
          }
        }
        userMenu {
          ... on Node {
            __typename
            ... on ContentfulArticlePage {
              ...ArticlePage
            }
            ... on ContentfulDropdownGrouping {
              ...DropdownGrouping
            }
            ... on ContentfulLink {
              ...Link
            }
            ... on ContentfulPage {
              ...Page
            }
          }
        }
      }
    }
  `)

  return contentfulApplication
}
