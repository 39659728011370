import React from 'react'
import Footer from '../footer/footer'
import Navbar from '../navbar'

type LayoutProps = {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <div className="font-sans">
    <Navbar />
    <main>{children}</main>
    <Footer />
  </div>
)

export default Layout
