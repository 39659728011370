import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

type HeroBannerProps = {
  image?: {
    file: {
      url: string
    }
    gatsbyImageData: IGatsbyImageData
  }
  text?: string
}

const HeroBanner = ({ image, text }: HeroBannerProps): JSX.Element => {
  if (!image && !text) {
    return <></>
  }

  return (
    <div className="relative z-20 bg-white">
      <div className="flex justify-between items-center max-w-2xl mx-auto space-x-4 py-4 px-4 sm:px-8">
        {text && <h2 className="text-gray-700">{text}</h2>}
        {image && (
          <div className="w-32">
            <GatsbyImage
              image={image.gatsbyImageData}
              imgStyle={{
                objectFit: 'contain',
                objectPosition: 'center',
              }}
              className="max-h-6"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default HeroBanner
