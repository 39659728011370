import { Header } from '@wunderdogsw/finbb-dms-theme'
import React from 'react'
import useApplicationNavbar from '../hooks/use-application-navbar'

type NavbarProps = {}

const Navbar: React.FC<NavbarProps> = ({}) => {
  const {
    announcementBlock,
    navigationCallToActionCaption,
    navigationCallToActionTarget,
    navigationMenu,
    userMenu,
    siteLogo,
    siteLogoInverse,
  } = useApplicationNavbar()

  return (
    <Header
      title="Fingenious"
      logo={siteLogo.resize.src}
      logoInverse={siteLogoInverse.resize.src}
      inverse={true}
      navigationMenu={navigationMenu}
      userMenu={userMenu}
      navigationCallToActionCaption={navigationCallToActionCaption}
      navigationCallToActionTarget={navigationCallToActionTarget}
      announcement={announcementBlock}
    />
  )
}
export default Navbar
